<script>
  import { Router, Route, links } from 'svelte-routing';

  import { user } from '../../stores/default.js';

  import Link from './NavLink.svelte';
</script>

<nav class="sidenav" use:links>
  <Link to="/">Updates</Link>
  <Link to="people">Directory</Link>
  <Link to="settings">Settings</Link>
  <Link to="help">Help</Link>
  <Link to="logout">Log Out</Link>
  {#if $user.isAdmin}
    <Link to="admin">Admin Panel</Link>
  {/if}
</nav>

<style global lang="postcss">
  .sidenav {
    @apply h-screen w-auto sticky top-0 px-2 pt-6 flex flex-col justify-start items-start;
    @apply bg-indigo-200;
  }

  .sidenav a {
    @apply text-lg text-gray-700 p-2 px-6 rounded-lg m-1 mx-2 bg-transparent whitespace-nowrap;
  }

  .sidenav a.active {
    @apply text-gray-800 bg-indigo-300 shadow;
  }

  .sidenav a:hover {
    @apply text-black underline;
  }

  .sidenav a.active:hover {
    @apply text-gray-800 no-underline shadow-lg;
  }
</style>
