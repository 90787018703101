<script>
  import { apiEndpoint, fetchOpts } from './../../stores/default';
  import { link } from 'svelte-routing';

  async function fetchUsers() {
    const res = await fetch(`${$apiEndpoint}/private/users/directory`, {
      ...$fetchOpts,
    });
    const json = await res.json();

    if (res.ok) return json;
    else return false;
  }

  let usersPromise = fetchUsers();

  const name = u => {
    const p = u.profile;
    let n = '';
    if (p.fname && p.fname.length > 0) n += p.fname;
    if (p.mname && p.mname.length > 0) n += ' ' + p.mname;
    if (p.lname && p.lname.length > 0) n += ' ' + p.lname;
    if (n.length > 0) return n;
    return `(no name) ${u.userSlug}`;
  };
</script>

<div class="w-full bg-gray-100 p-8">
  <h1 class="font-light">user directory</h1>
</div>

{#await usersPromise}
  <p>Loading directory...</p>
{:then users}
  <table>
    <tr>
      <th>name</th>
      <th>username</th>
      <th>email</th></tr
    >
    {#each users as user (user.userSlug)}
      <tr id={`u_${user.userSlug}`}>
        <td class="pl-2"
          ><a class="u" href={`/u/${user.userSlug}`} use:link>{name(user)}</a
          ></td
        >
        <td
          ><a class="u" href={`/u/${user.userSlug}`} use:link>{user.userSlug}</a
          ></td
        >
        <td
          ><a class="mailto" href={`mailto:${user.profile.email || user.email}`}
            >{user.profile.email || user.email}</a
          ></td
        >
      </tr>
    {/each}
  </table>
{:catch err}
  <p>Sorry, there was an error while loading the directory!</p>
{/await}

<style>
  table {
    @apply w-full px-4 rounded;
  }

  th {
    @apply p-2 pl-4 font-light text-lg text-left text-white bg-gray-500;
  }

  tr:nth-child(even) {
    @apply bg-gray-50;
  }

  tr:nth-child(odd) {
    @apply bg-indigo-50;
  }

  td {
    @apply p-2;
  }

  a.u,
  a.mailto {
    @apply text-gray-700 hover:text-indigo-600 underline;
  }
</style>
