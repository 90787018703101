<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { parseISO, formatRelative, isEqual } from 'date-fns';
  import { utcToZonedTime } from 'date-fns-tz';

  import { Link, link } from 'svelte-routing';

  import UpdateActions from './UpdateActions.svelte';

  import {
    user,
    locale,
    apiEndpoint,
    fetchOpts,
  } from './../../../stores/default';

  const dispatch = createEventDispatcher();

  const passUpRefreshUpdates = () => {
    dispatch('passUpRefreshUpdates');
  };

  const formatDate = date => {
    let parsed = parseISO(date);
    let zoned = utcToZonedTime(parsed, $locale);
    return formatRelative(zoned, Date.now());
  };

  const formatNumComments = n => {
    if (n == 0) return 'no comments';
    else if (n == 1) return '1 comment';
    else return n + ' comments';
  };

  const seeMoreText = numComments => {
    let r = 'see more';
    if (numComments > 0) {
      r = `${r} (${formatNumComments(numComments)})`;
    }
    return r;
  };

  const postedBySelf = () => {
    return postData.author.userSlug === $user.userSlug;
  };

  const authorName = () => {
    if (postedBySelf()) return 'you';
    return postData.author.userSlug;
  };

  export let postData;
</script>

<div
  class="update flex-shrink flex flex-col p-4 my-2 bg-white rounded-lg shadow-xl"
  class:self={postedBySelf()}
  class:pinned={postData.pinned}
>
  {#if postData.pinned}
    <span class="pin"><span class="emoji">📌</span> Pinned</span>
  {/if}
  {#if postData.html}
    <div class="u-html">
      {@html postData.html}
    </div>
    <div class="details">
      <div>
        <p>
          by <a href={`/u/${postData.author.userSlug}`} use:link
            >{authorName()}</a
          >
        </p>
        <p>
          posted {formatDate(postData.createdAt)}
        </p>
        {#if postData.edited}
          <p>
            last edited {formatDate(postData.edited)}
          </p>
        {/if}
        <Link to={`/update/${postData.slug}`}
          >{seeMoreText(postData.comments.length)}</Link
        >
      </div>
      <div class="flex flex-col justify-end items-end">
        {#if $user.isAdmin || String(postData.author._id) === String($user._id)}
          <UpdateActions
            updateData={postData}
            on:passUpRefreshUpdates={passUpRefreshUpdates}
          />
        {/if}
      </div>
    </div>
  {:else}
    <p>No post data found...</p>
  {/if}
</div>

<style lang="scss">
  .self {
    @apply border-2 border-indigo-400;
  }

  .details {
    @apply w-full flex flex-row justify-between mt-3 text-sm text-gray-700;
  }

  // /* style whats inside posts like this */
  .u-html :global(*) {
    @apply leading-normal;
  }

  .u-html :global(img) {
    @apply h-auto w-auto rounded shadow-sm my-1;
    max-width: 32rem;
    max-height: 24rem;
  }

  .u-html :global(p) {
    @apply leading-normal my-0.5;
    min-height: 1.5rem;
  }

  .u-html :global(ul) {
    @apply list-disc ml-8;
  }

  .u-html :global(:not(li) > ul) {
    @apply my-2 mb-4;
  }

  .u-html :global(ul ul) {
    list-style-type: circle;
  }

  .u-html :global(h1) {
    @apply font-light mb-1;
  }
  .u-html :global(h2) {
    @apply font-light mb-1;
  }
  .u-html :global(h3) {
    @apply font-light mb-1;
  }
  .u-html :global(h4) {
    @apply font-light mb-1;
  }

  .pinned {
    @apply border-2 border-gray-300;
  }

  .pinned span.pin {
    @apply flex flex-row items-center justify-start text-sm text-gray-500;
  }

  .pinned span.pin .emoji {
    @apply text-lg mr-1;
  }
</style>
