<script>
  import { getNotificationsContext } from 'svelte-notifications';

  import { link, navigate } from 'svelte-routing';

  import {
    authed,
    user,
    token,
    tokenExp,
    apiEndpoint,
    fetchOpts,
  } from '../../stores/default';

  const { addNotification } = getNotificationsContext();

  const alert = (text, type = 'warning') =>
    addNotification({
      text: text,
      position: 'top-right',
      type: type,
      removeAfter: 10000,
    });

  const data = {
    email: '',
    password: '',
  };

  async function handleSubmit(e) {
    data.email = data.email.toLowerCase();

    fetch(`${$apiEndpoint}/login`, {
      ...$fetchOpts,
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(json => {
        if (json.user) {
          if (json.user.profile) {
            if (json.user.profile.birthdate !== null)
              json.user.profile.birthdate =
                json.user.profile.birthdate.substring(0, 10);
            else json.user.profile.birthdate = null;
          } else json.user.profile = {};

          $authed = true;
          $token = json.token;
          $tokenExp = json.expires;
          $user = json.user;
          $fetchOpts.headers['X-Access-Token'] = json.token;

          localStorage.setItem('jwt_token', json.token);
          localStorage.setItem('jwt_exp', json.expires);

          alert('Logged in.', 'success');
          navigate('/');
        } else {
          alert(json.msg, 'warning');
        }
      })
      .catch(e => {
        alert('Sorry, there was an error. Please try again later.', 'danger');
        console.trace(e);
      });
  }
</script>

<section
  class="flex flex-col justify-center items-center bg-gray-50 rounded shadow p-1 mb-2"
>
  First time here? <a
    href="/signup"
    class="border-0 p-2 underline text-indigo-400 text-lg"
    use:link
    >Click here to set up your account.
  </a>
</section>
<form
  on:submit|preventDefault={handleSubmit}
  class="flex-shrink-0 w-auto mx-auto flex flex-col p-6 bg-gray-100 rounded-lg
  shadow-xl space-y-2"
>
  <h2 class="text-2xl text-center p-2">Log In</h2>
  <p class="text-center">This site is authentication-only.</p>
  <div class="fieldset">
    <label for="email" class="text-base">Email:</label>
    <input
      bind:value={data.email}
      type="email"
      id="email"
      name="email"
      placeholder="Your email here"
    />
  </div>
  <div class="fieldset">
    <label for="password" class="text-base">Password:</label>
    <input
      bind:value={data.password}
      type="password"
      id="password"
      name="password"
      placeholder="Your password here"
    />
  </div>
  <div class="flex justify-center">
    <button
      type="submit"
      class="m-4 p-2 bg-white rounded border border-gray-200
      hover:border-gray-400"
    >
      Log In
    </button>
  </div>
  <a href="/forgot-password" class="reset" use:link>Forgot your password?</a>
</form>

<style lang="scss">
  input,
  button {
    transition: 0.2s ease-in-out;
  }

  input {
    @apply w-full p-2 text-base border border-gray-300 rounded;

    &:focus {
      @apply border-gray-800;
    }
  }

  .fieldset {
    @apply w-full flex flex-col justify-start items-start;
  }

  a.reset {
    @apply border-0 bg-transparent underline text-indigo-700 text-center;
  }
</style>
