<script>
  import { createEventDispatcher } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';

  import { link, navigate } from 'svelte-routing';

  import { authed, apiEndpoint, fetchOpts } from '../../stores/default';

  $authed && navigate('/');

  const dis = createEventDispatcher();
  const { addNotification } = getNotificationsContext();

  const data = {
    email: '',
    password: '',
    passwordConf: '',
    token: '',
  };

  let accountCreated = false;

  const passwordSufficient = () => data.password.length >= 8;
  const passwordsMatch = () => data.password === data.passwordConf;

  const alert = (text, type = 'warning') =>
    addNotification({
      text: text,
      position: 'top-right',
      type: type,
      removeAfter: 10000,
    });

  const toggleForm = () => dis('toggle');

  async function handleSubmit() {
    if (!passwordSufficient())
      return alert('Your password must be at least 8 characters.');

    if (!passwordsMatch())
      return alert('Your passwords do not match, please try again.');

    data.email = data.email.toLowerCase();

    fetch(`${$apiEndpoint}/user`, {
      ...$fetchOpts,
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          alert('Success! Please log in.', 'success');
          toggleForm();
        } else {
          alert(`Error: ${json.msg}`, 'danger');
        }
      })
      .catch(e => {
        alert('Sorry, there was an error. Please try again later.', 'danger');
        console.log(e);
      });
  }
</script>

<section
  class="p-2 mb-2 bg-gray-100 rounded shadow  flex flex-col justify-center items-center"
>
  <a href="/" class="border-0 bg-transparent underline" use:link>
    I have an account! Log in instead.
  </a>
</section>
<form
  on:submit|preventDefault={handleSubmit}
  class="flex-shrink-0 w-auto mx-auto flex flex-col p-6 bg-gray-100 rounded-lg
  shadow-xl space-y-2"
>
  <h2 class="text-2xl text-center p-2">Sign Up</h2>
  <div class="fieldset">
    <label for="email" class="text-base">Email:</label>
    <input
      bind:value={data.email}
      type="email"
      id="password"
      name="password"
      placeholder="Your email"
      class="p-2 text-base border border-gray-300 rounded focus:border-gray-800"
    />
  </div>
  <div class="fieldset">
    <label for="password" class="text-base">Password:</label>
    <input
      bind:value={data.password}
      type="password"
      id="password"
      name="password"
      placeholder="Your password"
      class="p-2 text-base border border-gray-300 rounded focus:border-gray-800"
    />
  </div>
  <div class="fieldset">
    <label for="passwordConf" class="text-base">Confirm password:</label>
    <input
      bind:value={data.passwordConf}
      type="password"
      id="passwordConf"
      name="passwordConf"
      placeholder="Confirm password"
      class="p-2 text-base border border-gray-300 rounded focus:border-gray-800"
    />
  </div>
  <div class="flex flex-col justify-start items-start">
    <label for="token" class="text-base">Signup key / token:</label>
    <input
      bind:value={data.token}
      type="text"
      id="token"
      name="token"
      placeholder="Signup key / token"
      class="p-2 text-base border border-gray-300 rounded focus:border-gray-800"
    />
  </div>
  <div class="flex justify-center">
    <button
      type="submit"
      class="m-4 p-2 bg-white rounded border border-gray-200
      hover:border-gray-400"
    >
      Create Account
    </button>
  </div>
</form>
{#if accountCreated}
  <div
    class="p-4 m-4 w-auto mx-auto flex-shrink-0 flex flex-col bg-gray-200
    rounded-lg shadow-xl space-y-2"
  >
    <p class="text-center">Account created!</p>
    <a href="auth/login" use:link>Click here to log in</a>
  </div>
{/if}

<style>
  input,
  button {
    transition: 0.2s ease-in-out;
  }

  input {
    @apply w-full;
  }

  .fieldset {
    @apply w-full flex flex-col justify-start items-start;
  }
</style>
