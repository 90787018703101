<script>
  import { userList } from '../../stores/admin';

  import { user, apiEndpoint, fetchOpts } from './../../stores/default';

  let visible = false;

  const toggleVisible = () => {
    visible = !visible;
  };

  const handleChangeAdminUser = userData => {
    fetch(`${$apiEndpoint}/private/users/makeAdmin`, {
      ...$fetchOpts,
      method: 'PUT',
      body: JSON.stringify({
        id: userData._id,
        setAdmin: !userData.isAdmin,
      }),
    })
      .then(res => res.json())
      .then(json => {
        if (!json.success) throw new Error(json.res);

        let newUserList = [...$userList];

        console.log(json);
        newUserList.forEach(u => {
          if (String(u._id) === String(userData._id)) {
            u.isAdmin = json.user.isAdmin;
            return;
          }
        });

        $userList = newUserList;
      })
      .catch(err => {
        console.log('Error updating user admin data', err);
      });
  };

  const handleDeleteUser = deleteId => {
    fetch(`${$apiEndpoint}/private/users`, {
      ...$fetchOpts,
      method: 'DELETE',
      body: JSON.stringify({ id: deleteId }),
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        $userList = $userList.filter(u => u._id !== json.user._id);
      })
      .catch(err => {
        console.log('Error deleting user!', err);
      });
  };

  export let userToEdit;
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative inline-block text-left">
  <div>
    <button
      type="button"
      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      id="options-menu"
      aria-haspopup="true"
      aria-expanded="true"
      on:click={toggleVisible}
    >
      Options
      <svg
        class="-mr-1 ml-2 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>

  <div class={visible ? 'menu' : 'menu hidden'}>
    <div
      class="py-1"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <button
        class="option-button"
        role="menuitem"
        on:click={handleChangeAdminUser(userToEdit)}>Toggle Admin Status</button
      >
      <button
        class="option-button"
        role="menuitem"
        on:click={handleDeleteUser(userToEdit._id)}>Delete Account</button
      >
    </div>
  </div>
</div>

<style lang="scss">
  .option-button {
    @apply block px-4 py-2 text-sm text-gray-700 border-0 bg-transparent;

    &:hover {
      @apply bg-gray-100 text-gray-900;
    }
  }

  .menu {
    @apply origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white;
    z-index: 2;

    &.hidden {
      display: none;
    }
  }
</style>
