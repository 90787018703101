<script>
  import { navigate } from 'svelte-routing';

  import { authed, apiEndpoint, fetchOpts } from '../../stores/default';

  const handleClick = () => {
    fetch(`${$apiEndpoint}/logout`, { ...$fetchOpts })
      .then(res => res.json())
      .then(json => {
        $authed = false;
        $fetchOpts.headers['X-Access-Token'] = '';
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('jwt_exp');
        navigate('/');
      })
      .catch(err => {
        throw new Error(err);
      });
  };
</script>

<div class="p-4">
  <button
    class="bg-indigo-100 text-indigo-800 border border-indigo-800 rounded hover:bg-indigo-300 hover:shadow cursor-pointer"
    on:click={handleClick}>Click here to Log Out</button
  >
</div>
