<script>
  import { getNotificationsContext } from 'svelte-notifications';

  import { apiEndpoint, fetchOpts, user } from '../../stores/default';

  import DeleteAccountButton from './account/DeleteAccountButton.svelte';

  const { addNotification } = getNotificationsContext();

  let userInfo = {
    ...$user,
  };

  if (!userInfo.profile.address)
    userInfo.profile.address = {
      street: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };

  if (!userInfo.profile.bio) userInfo.profile.bio = '';

  const MAX_BIO_LENGTH = 400;

  const isAdmin = $user.isAdmin;

  delete userInfo.isAdmin;
  delete userInfo.createdAt;
  delete userInfo.updatedAt;
  delete userInfo.__v;
  delete userInfo.profile.createdAt;
  delete userInfo.profile.updatedAt;
  delete userInfo.profile.__v;

  const alert = (text, type = 'warning') =>
    addNotification({
      text: text,
      position: 'top-right',
      type: type,
      removeAfter: 10000,
    });

  const handleSave = async () => {
    let sendme = { ...userInfo };

    try {
      const res = await fetch(`${$apiEndpoint}/private/users/${$user._id}`, {
        ...$fetchOpts,
        method: 'PUT',
        body: JSON.stringify(sendme),
      });

      if (res.ok) {
        const json = await res.json();
        if (json.user) {
          $user = { ...json.user };
          alert('Profile updated!', 'success');
        } else {
          alert(json.msg || 'Error, please try again later.');
        }
      } else throw new Error();
    } catch (err) {
      alert(`Error updating user info, please try again soon. Sorry!`);
      console.error(res);
    }
  };

  const handleChangeWeeklyEmail = async () => {
    try {
      const res = await fetch(`${$apiEndpoint}/private/users/${$user._id}`, {
        ...$fetchOpts,
        method: 'PUT',
        body: JSON.stringify({
          weeklyEmailUpdates: $user.weeklyEmailUpdates,
        }),
      });

      if (res.ok) {
        alert(`Updated email preferences.`, 'success');
      } else throw new Error();
    } catch (err) {
      alert(`Error saving email preference, please try again soon.`);
      console.error(res);
    }
  };

  let newpw = { pw1: '', pw2: '' };

  const handleChangePassword = async () => {
    if (newpw.pw1 !== newpw.pw2) {
      alert('Passwords do not match');
      return;
    }

    if (newpw.pw1.length < 8) {
      alert('Must be at least 8 characters long');
      return;
    }

    try {
      const res = await fetch(`${$apiEndpoint}/private/reset-password`, {
        ...$fetchOpts,
        method: 'POST',
        body: JSON.stringify({ ...newpw }),
      });

      newpw.pw1 = '';
      newpw.pw2 = '';

      if (res.ok) {
        alert(`Password changed successfully.`, 'success');
      } else {
        console.log(res);
        throw new Error();
      }
    } catch (err) {
      alert(`Sorry, there was an error.`, 'warning');
    }
  };

  let showingDeleteButton = false;
  const toggleShowingDeleteButton = () => {
    showingDeleteButton = !showingDeleteButton;
  };
</script>

<header class="p-8 bg-gray-100">
  <h1>your settings</h1>
</header>
<section class="weekly">
  <div>
    <label for="weeklyEmail">Receive weekly summary email:</label>
    <input
      type="checkbox"
      id="weeklyEmail"
      class="ml-2"
      bind:checked={$user.weeklyEmailUpdates}
      on:change={handleChangeWeeklyEmail}
    />
    <p>
      A short weekly summary email, sent on sundays at noon EST, to {$user.email}.
    </p>
    <p>You'll only get an email if anything has happened.</p>
  </div>
</section>
<section class="details">
  <div class="settings-section">
    <div class="set">
      <label for="fname">first name:</label>
      <input type="text" id="fname" bind:value={userInfo.profile.fname} />
    </div>
    <div class="set">
      <label for="mname">middle name(s):</label>
      <input type="text" id="mname" bind:value={userInfo.profile.mname} />
    </div>
    <div class="set">
      <label for="lname">last name:</label>
      <input type="text" id="lname" bind:value={userInfo.profile.lname} />
    </div>
    <div class="set">
      <label for="slug">username:</label>
      <input type="text" id="slug" bind:value={userInfo.userSlug} />
    </div>
    <div class="set">
      <label for="birthdate">birthday:</label>
      <input
        type="date"
        id="birthdate"
        bind:value={userInfo.profile.birthdate}
      />
    </div>
    <div class="set">
      <label for="phone">phone:</label>
      <input type="tel" id="phone" bind:value={userInfo.profile.phone} />
    </div>
    <textarea
      class="bio"
      bind:value={userInfo.profile.bio}
      placeholder="write a little bio here!"
    />
    <p
      class={`text-sm text-gray-600 ${
        userInfo.profile.bio.length > MAX_BIO_LENGTH ? 'red' : ''
      }`}
    >
      {userInfo.profile.bio.length}/{MAX_BIO_LENGTH} characters
    </p>
  </div>
  <div class="settings-section">
    <div class="address-cont">
      <h3 class="text-center text-gray-800 font-light text-lg mt-2 mb-4">
        address
      </h3>
      <div class="set">
        <label for="add_street">street:</label>
        <input
          type="text"
          id="add_street"
          bind:value={userInfo.profile.address.street}
        />
      </div>
      <div class="set">
        <label for="add_street2">street 2:</label>
        <input
          type="text"
          id="add_street2"
          bind:value={userInfo.profile.address.street2}
        />
      </div>
      <div class="set">
        <label for="add_city">city:</label>
        <input
          type="text"
          id="add_city"
          bind:value={userInfo.profile.address.city}
        />
      </div>
      <div class="set">
        <label for="add_state">state:</label>
        <input
          type="text"
          id="add_state"
          bind:value={userInfo.profile.address.state}
        />
      </div>
      <div class="set">
        <label for="add_zip">zip:</label>
        <input
          type="text"
          id="add_zip"
          bind:value={userInfo.profile.address.zip}
        />
      </div>
      <div class="set">
        <label for="add_country">country:</label>
        <input
          type="text"
          id="add_country"
          bind:value={userInfo.profile.address.country}
        />
      </div>
    </div>
  </div>
  <div class="w-full p-2 flex justify-center">
    <button class="save-btn" on:click={handleSave}>Save Changes</button>
  </div>
</section>
<section class="p-4 bg-gray-50 rounded-lg shadow m-2">
  <h2>Change Your Password</h2>
  <form on:submit|preventDefault={handleChangePassword}>
    <input type="password" placeholder="new password" bind:value={newpw.pw1} />
    <input type="password" placeholder="and again..." bind:value={newpw.pw2} />
    <button type="submit" class="password-btn">Change</button>
  </form>
</section>

<section class="p-4">
  <div class="pane">
    <h2>Delete Account</h2>
    <p>
      Careful! There's no undo button, and this deletes all your posts,
      comments, files, and profile settings. There's really no undo button!
    </p>
    <button
      class="text-red-800 p-2 rounded shadow-lg cursor-pointer"
      on:click={toggleShowingDeleteButton}
    >
      show me the scary red button
    </button>
    <div class:hide={!showingDeleteButton}>
      <DeleteAccountButton />
    </div>
  </div>
</section>

<style lang="postcss">
  .settings-section {
    @apply w-1/2 p-4 my-1 rounded;
  }

  /* larger container with info and address divs inside */
  .details {
    @apply flex flex-wrap mx-2 border-2 rounded border-gray-200 bg-blue-100;
  }

  .set {
    @apply flex items-baseline w-full justify-between my-2;
  }

  .weekly {
    @apply p-2 w-auto;
  }

  .weekly > div {
    @apply p-2 px-4 my-0 bg-indigo-100 rounded-lg shadow-sm;
  }

  .weekly p {
    @apply text-sm text-gray-800 mt-1.5;
  }

  .settings-section label {
    @apply mr-12 whitespace-nowrap;
  }

  .settings-section input {
    @apply flex-grow max-w-2xl;
  }

  .address-cont {
    @apply px-4 py-2 bg-gray-50 rounded-lg shadow;
  }

  .address-cont input {
    @apply p-1.5;
  }

  .save-btn {
    @apply text-gray-700 hover:border-green-500 hover:bg-green-100 hover:text-green-600;
  }

  .password-btn {
    @apply border-yellow-600 text-yellow-700 bg-yellow-50;
  }

  .bio {
    @apply w-full shadow;
    min-height: 10rem;
  }

  @media (max-width: 1024px) {
    .settings-section {
      @apply w-full;
    }

    .settings-section input {
      @apply max-w-lg;
    }
  }

  .red {
    @apply text-red-500;
  }

  .hide {
    @apply hidden;
  }
</style>
