<script>
  let pn = window.location.pathname;
</script>

<section>
  <div class="inner">
    <h1 class="code">404</h1>
    <div class="details">
      <h3>Couldn't find that!</h3>
      <p>
        Sorry, we couldn't find the resource at <strong>{String(pn)}.</strong>
      </p>
    </div>
  </div>
</section>

<style>
  section {
    @apply w-full min-h-screen p-4 bg-gradient-to-r from-indigo-300 to-white;
    @apply flex justify-center items-center;
  }

  .inner {
    @apply flex flex-row justify-center items-center;
  }

  .code {
    @apply p-4 border-r-2 border-gray-600;
  }

  .details {
    @apply text-sm p-4;
  }
</style>
