<script>
</script>

<div class="flex flex-col p-4">
  <section class="bg-transparent">
    <h1 class="mb-4">Need Some Help?</h1>
    <p>
      If you have any questions or comments, please contact Lars at <a
        class="text-indigo-500"
        href="mailto:lars@aloft.software">lars@aloft.software</a
      >!
    </p>
  </section>
</div>

<style>
  section {
    @apply flex flex-col bg-white p-4 rounded my-1;
  }

  /* ul {
    @apply list-disc ml-4;
  } */
</style>
