<script>
  import { getContext } from 'svelte';
  import { navigate } from 'svelte-routing';

  import {
    apiEndpoint,
    fetchOpts,
    user,
    authed,
  } from './../../../stores/default';

  import Dialog from './../../../components/modals/Dialog.svelte';

  const { open } = getContext('simple-modal');

  const onCancel = () => {};

  const onOkay = () => {
    fetch(`${$apiEndpoint}/private/users`, {
      ...$fetchOpts,
      method: 'DELETE',
      body: JSON.stringify({ id: $user._id }),
    })
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          window.alert('Account deleted!');
          $user = {};
          $authed = false;
          navigate('auth');
        }
      });
  };

  const confirm = () => {
    open(
      Dialog,
      {
        message:
          'Are you sure you want to delete your account? This cannot be undone!',
        hasForm: false,
        onCancel,
        onOkay,
        yesButtonText: 'Yes',
      },
      {
        closeOnEsc: false,
        closeOnOuterClick: false,
      }
    );
  };
</script>

<button on:click={confirm}>Delete Account (!)</button>

<style>
  button {
    @apply font-bold text-red-800 bg-red-200 m-2 p-4 rounded shadow-lg border-2 border-red-800;
  }
</style>
