<script>
  import { onMount } from 'svelte';
  import { Router, Route, navigate } from 'svelte-routing';
  import Notifications from 'svelte-notifications';

  import {
    authed,
    apiEndpoint,
    fetchOpts,
    token,
    tokenExp,
    user,
  } from './stores/default.js';

  import Home from './pages/index/Index.svelte';
  import Auth from './pages/auth/Container.svelte';

  export let url = '';

  onMount(() => {
    const jwt = localStorage.getItem('jwt_token');
    if (jwt) $fetchOpts.headers['X-Access-Token'] = jwt;

    // If a jwt was saved, try auto-login
    if (!$authed && jwt) {
      let opts = { ...$fetchOpts };
      opts.method = 'POST';

      fetch(`${$apiEndpoint}/login/jwt`, opts)
        .then(res => {
          if (res.ok) return res.json();
          throw res.err;
        })
        .then(json => {
          if (json.user) {
            if (json.user.profile) {
              if (json.user.profile.birthdate !== null)
                json.user.profile.birthdate =
                  json.user.profile.birthdate.substring(0, 10);
              else json.user.profile.birthdate = null;
            } else json.user.profile = {};

            $authed = true;
            $user = json.user;
            $token = json.token;
            $tokenExp = json.expires;
            $fetchOpts.headers['X-Access-Token'] = json.token;
            localStorage.setItem('jwt_token', json.token);
            localStorage.setItem('jwt_exp', json.expires);

            navigate(window.location.pathname);
          }
        })
        .catch(err => {
          navigate('/');
        });
    } else navigate('/');
  });
</script>

<svelte:head>
  <title>GillKendig.net</title>
</svelte:head>

<Router {url}>
  <Notifications>
    {#if $authed}
      <Home />
    {:else}
      <Auth />
    {/if}
  </Notifications>
</Router>
