<script>
  import { apiEndpoint, fetchOpts } from './../../stores/default';
  import { link } from 'svelte-routing';

  import { format, parseISO } from 'date-fns';

  const formatDate = isostr => {
    const dt = new Date(parseISO(isostr));
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    ); // fix timezone issue for date with no time
    return format(dtDateOnly, 'MMMM do, Y');
  };

  const name = u => {
    const p = u.profile;
    let n = '';
    if (p.fname && p.fname.length > 0) n += p.fname;
    if (p.mname && p.mname.length > 0) n += ' ' + p.mname;
    if (p.lname && p.lname.length > 0) n += ' ' + p.lname;
    if (n.length > 0) return n;
    return '(no name)';
  };

  export let slug;

  let errorCode;

  async function fetchUser() {
    const res = await fetch(
      `${$apiEndpoint}/private/users/slug/${slug}?postHistory=true`,
      {
        ...$fetchOpts,
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      errorCode = res.status;
      throw errorCode;
    }
  }

  let userPromise = fetchUser();
</script>

<div class="p-4">
  <a class="text-sm text-gray-600 no-underline" href="/people" use:link
    >&larr; back to directory</a
  >
  {#await userPromise}
    <p>Loading...</p>
  {:then user}
    <section class="profile">
      <h3 class="text-xl">
        {name(user)}
      </h3>
      <h4 class="text-gray-600 font-light mb-4">
        {user.userSlug}
      </h4>
      {#if user.profile.bio && user.profile.bio.length > 0}
        <p class="bio">{user.profile.bio}</p>
      {/if}

      {#if user.profile.birthdate}
        <p>born {formatDate(user.profile.birthdate)}</p>
      {/if}
      <p>
        email: <a href={`mailto:${user.profile.email || user.email}`}
          >{user.profile.email || user.email}</a
        >
      </p>
      {#if user.profile.phone}
        <p>
          tel: <a href={`tel:${user.profile.phone}`}>{user.profile.phone}</a>
        </p>
      {/if}
      {#if user.profile.address}
        <div class="address">
          {#if user.profile.address.street}
            <p>{user.profile.address.street}</p>
          {/if}
          {#if user.profile.address.street2}
            <p>{user.profile.address.street2}</p>
          {/if}
          {#if user.profile.address.city || user.profile.address.state || user.profile.address.zip}
            <p>
              {`${
                user.profile.address.city
                  ? user.profile.address.city + ', '
                  : ''
              }${
                user.profile.address.state
                  ? user.profile.address.state + ' '
                  : ''
              }${user.profile.address.zip ? user.profile.address.zip : ''}`}
            </p>
          {/if}
          {#if user.profile.address.country}
            <p>{user.profile.address.country}</p>
          {/if}
        </div>
      {/if}
    </section>
    {#if user.updates && user.updates.length > 0}
      <section class="links">
        <h3 class="text-xl mb-2">posted links</h3>
        {#each user.updates as update}
          {#if update.links && update.links.length > 0}
            <div class="link">
              <p class="text-indigo-800">
                {format(parseISO(update.createdAt), 'MMMM do, yyyy')}
              </p>
              {#each update.links as link}
                <ul class="list-none">
                  <li class="text-gray-800 underline">{@html link}</li>
                </ul>
              {/each}
            </div>
          {/if}
        {/each}
      </section>{/if}
  {:catch err}
    <p>Error getting data, sorry!</p>
    {#if errorCode == 404}
      <p>It looks like that user does not exist.</p>
    {:else}
      <p>Error {errorCode}</p>
      <p>Please try again later!</p>
    {/if}
  {/await}
</div>

<style lang="scss">
  h3 {
    @apply font-light;
  }

  // a {
  //   @apply underline hover:text-indigo-600 hover:underline;
  // }

  section {
    @apply bg-white rounded shadow p-8 my-2;
  }

  .link li {
    @apply flex flex-col space-y-1 ml-4;
  }

  .bio {
    @apply my-4;
    white-space: pre-line;
  }

  .address {
    @apply mt-4;
  }
</style>
