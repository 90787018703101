import { readable, writable } from 'svelte/store';

const url = env_production ? '/' : 'http://localhost:4000/';

export const publicEndpoint = readable(url);
export const apiEndpoint = readable(`${url}api`);

export const fetchOpts = writable({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const token = writable('');
export const tokenExp = writable('');

export const authed = writable(false);
export const user = writable({});
export const locale = writable(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);

export const editingUpdateDelta = writable({});

export const APP_VERSION = readable('0.2.1');
