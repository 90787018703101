<script>
  import { onMount } from 'svelte';
  import { Router, Link, Route, navigate } from 'svelte-routing';

  import { APP_VERSION, authed, user } from '../../stores/default';

  import SideNav from '../../components/navs/AuthedSideNav.svelte';

  import Modal from './../../components/modals/Modal.svelte';

  import LatestUpdates from './latestupdates/Index.svelte';
  import MakeUpdate from './MakeUpdate.svelte';
  import Messages from './Messages.svelte';
  import Settings from './Settings.svelte';
  import Directory from './Directory.svelte';
  import Person from './Person.svelte';
  import Help from './Help.svelte';

  import Update from './Update.svelte';
  import LogOut from './Logout.svelte';

  import Admin from './../admin/Admin.svelte';

  import NotFound from './../errors/NotFound.svelte';

  export let url = '';
</script>

<Modal>
  {#if $user.isAdmin}
    <div
      class="w-full flex justify-center items-center p-2 bg-purple-200 font-semibold"
    >
      <p>Howdy, admin.</p>
    </div>
  {/if}
  <div class="w-full min-h-screen flex flex-row items-stretch">
    <SideNav {url} />
    <main class="flex-grow bg-gray-50 flex flex-col justify-start w-full">
      {#if $user.isAdmin}
        <Route path="/admin" component={Admin} />
      {/if}

      <!-- <Route path="/cloud" component={Cloud} /> -->
      <Route path="/settings" component={Settings} />
      <Route path="/messages" component={Messages} />
      <Route path="/people" component={Directory} />
      <Route path="/help" component={Help} />

      <Route path="/make-update" component={MakeUpdate} />
      <Route path="/update/:slug" component={Update} />

      <Route path="/u/:slug" let:params>
        <Person slug={params.slug} />
      </Route>

      <Route path="/logout" component={LogOut} />
      <!-- default = timeline -->
      <Route path="/" exact component={LatestUpdates} />
      <Route>
        <NotFound />
      </Route>
    </main>
  </div>
  <footer
    class="w-full flex flex-col justify-center items-center p-4 bg-indigo-800 text-gray-50"
  >
    <p class="font-extralight">
      &copy; 2021-2022, <a
        href="https://aloft.software"
        target="_blank"
        class="underline text-blue-200">aloft software</a
      >
    </p>
  </footer>
</Modal>
