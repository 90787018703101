<script>
  import { onMount } from 'svelte';
  import { formatRelative, formatDistance } from 'date-fns';
  import { navigate, link } from 'svelte-routing';

  import { apiEndpoint, fetchOpts, user } from './../../stores/default';

  import IoIosArrowRoundForward from 'svelte-icons/io/IoIosArrowRoundForward.svelte';

  export let slug;

  let updateId;

  let updatePromise = fetchUpdate(slug);
  let commentsPromise = fetchComments(slug);

  const refreshComments = () => {
    commentsPromise = fetchComments(slug);
  };

  async function fetchUpdate(slug) {
    const res = await fetch(`${$apiEndpoint}/private/updates/${slug}`, {
      ...$fetchOpts,
    });

    if (res.ok) {
      const json = await res.json();
      updateId = json._id;
      return json;
    }

    return false;
  }

  async function fetchComments(slug) {
    const res = await fetch(`${$apiEndpoint}/private/comment/update/${slug}`, {
      ...$fetchOpts,
    });
    const json = await res.json();

    if (res.ok) return json;
    else return false;
  }

  // New comment
  let newComment = '';

  async function handleSubmitComment() {
    const res = await fetch(`${$apiEndpoint}/private/comment/update`, {
      ...$fetchOpts,
      method: 'POST',
      body: JSON.stringify({
        body: newComment,
        parentUpdateId: updateId,
      }),
    });

    if (res.ok) {
      newComment = '';
      refreshComments();
    } else {
      console.log('Error saving comment, sorry! Try again later.');
    }
  }

  const postedBySelf = authorSlug => {
    return authorSlug == $user.userSlug;
  };

  const authorName = authorSlug => {
    if (postedBySelf(authorSlug)) return 'you';
    return authorSlug;
  };

  // onMount(() => {
  // });
</script>

<div class="w-full p-4">
  <section>
    {#await updatePromise}
      <p>loading...</p>
    {:then update}
      {#if update}
        <p class="font-light text-gray-500 text-sm mb-2">
          {update.author.fname || update.author.userSlug} wrote this {formatDistance(
            new Date(update.createdAt),
            new Date(),
            { addSuffix: true }
          )}
        </p>
        <div class="u-html">
          {@html update.html}
        </div>
        <div class="details">
          <p>
            by <a href={`/u/${update.author.userSlug}`} use:link
              >{authorName(update.author.userSlug)}</a
            >
          </p>
          <p>posted {formatRelative(new Date(update.createdAt), new Date())}</p>
          {#if update.edited}
            <p>
              last edited {formatRelative(new Date(update.edited), new Date())}
            </p>
            <p>edited {update.numEdits || 1}x</p>
          {/if}
        </div>
      {:else}
        <p>Sorry, that update could not be found.</p>
      {/if}
    {:catch err}
      {err}
    {/await}
  </section>
  {#if updateId}
    <section>
      <h2>Comments</h2>
      <form
        class="newCommentCont"
        on:submit|preventDefault={handleSubmitComment}
      >
        <textarea
          name="writeComment"
          id="writeComment"
          class="flex-grow p-1"
          placeholder="write a comment"
          bind:value={newComment}
        />
        <button type="submit" class="submit-btn"
          ><IoIosArrowRoundForward /></button
        >
      </form>
      {#await commentsPromise}
        <p>loading comments...</p>
      {:then comments}
        {#if comments}
          {#if comments.length > 0}
            <ul class="comments-list">
              {#each comments as comment}
                <li
                  class={postedBySelf(comment.author.userSlug)
                    ? 'comment byself'
                    : 'comment'}
                >
                  <div class="text-sm text-gray-500">
                    {authorName(comment.author.userSlug)} | {formatRelative(
                      new Date(comment.createdAt),
                      new Date()
                    )}
                  </div>
                  {comment.body}
                </li>
              {/each}
            </ul>
          {:else}
            <p><em>no comments yet</em></p>
          {/if}
        {:else}
          <p>Sorry, there was an error getting comments.</p>
        {/if}
      {/await}
    </section>{/if}
</div>

<style>
  section {
    @apply bg-white my-3 p-4 rounded-lg shadow;
  }

  .u-html :global(*) {
    @apply leading-loose;
  }

  .u-html :global(p) {
    @apply leading-relaxed;
  }

  .u-html :global(ul) {
    @apply list-disc ml-8;
  }

  .u-html :global(:not(li) > ul) {
    @apply my-2 mb-4;
  }

  .u-html :global(ul ul) {
    list-style-type: circle;
  }

  .u-html :global(h1) {
    @apply font-light mb-1;
  }
  .u-html :global(h2) {
    @apply font-light mb-1;
  }
  .u-html :global(h3) {
    @apply font-light mb-1;
  }
  .u-html :global(h4) {
    @apply font-light mb-1;
  }

  .u-html :global(p) {
    @apply mx-2;
  }

  .u-html :global(img) {
    @apply w-auto h-auto;
    max-width: 30vw;
    max-height: 40vh;
  }

  .details {
    @apply flex flex-col justify-start items-start mt-3;
  }

  .details > p {
    @apply text-sm text-gray-600;
  }

  .newCommentCont {
    @apply w-full inline-flex flex-wrap my-2;
  }

  .submit-btn {
    @apply h-auto w-12 ml-3;
  }

  .comments-list {
    @apply flex flex-col items-start justify-start;
  }

  .comment {
    @apply border-l-4 border-gray-300 p-2;
  }

  .comment.byself {
    @apply border-indigo-400;
  }
</style>
