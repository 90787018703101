<script>
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';

  import {
    apiEndpoint,
    publicEndpoint,
    user,
    fetchOpts,
  } from './../../../stores/default';

  import UpdateComponent from './Update.svelte';

  let images = [
    'image01.jpg',
    'image02.jpg',
    'image03.jpg',
    'image04.jpg',
    'image05.jpg',
    'image06.jpg',
    'image07.png',
    'image08.jpeg',
    'image09.jpeg',
    'image10.jpeg',
    'image11.jpeg',
    'image12.jpeg',
  ];

  const greeting = () => {
    let howdy = 'Hello';
    let g = `${howdy}, ${$user.profile.fname || $user.userSlug}`;
    return g;
  };

  const getImage = () => {
    const i = `/img/${images[Math.floor(Math.random() * images.length)]}`;
    return i;
  };

  const getPosts = async () => {
    let opts = { ...$fetchOpts };

    const res = await fetch(`${$apiEndpoint}/private/updates/latest`, opts);
    const json = await res.json();

    if (res.ok) return json;
    else throw new Error(json);
  };

  let postsPromise = getPosts();

  const refreshUpdates = () => {
    console.log('Refreshing updates...');
    postsPromise = getPosts();
  };
</script>

<header>
  <h1>{greeting()}</h1>
  <img src={getImage()} alt="" loading="lazy" />
</header>
<div class="caption">
  <p>Gill family reunion, 1915</p>
</div>
<section class="updates-nav">
  <a href="/make-update" use:link>Post an Update</a>
</section>
<section class="flex flex-col justify-center items-start px-4">
  {#await postsPromise}
    <p>Loading most recent posts...</p>
  {:then posts}
    {#if posts.length < 1}
      <div class="p-3 m-3 bg-gray-100 rounded-sm shadow-sm">
        <h2>No updates found!</h2>
        <p>You could be the first to post something here!</p>
      </div>
    {:else}
      {#each posts as post}
        <UpdateComponent
          postData={post}
          on:passUpRefreshUpdates={refreshUpdates}
        />
      {/each}
    {/if}
  {:catch error}
    <h3>Sorry, there was an error! Please try again later.</h3>
    <p>{error}</p>
  {/await}
</section>

<style>
  :global(section a) {
    @apply underline;
  }

  :global(section a:hover) {
    @apply no-underline;
  }

  header {
    @apply w-full flex justify-between items-center p-6 font-light;

    /* background image with gray translucent cover over top */
    background-image: linear-gradient(
        to right,
        rgba(10, 10, 10, 0.65),
        rgba(10, 10, 10, 0.45)
      ),
      url('/img/gillreunion.jpeg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  header h1 {
    @apply font-extralight text-4xl text-indigo-100 border-b-2 border-indigo-300 py-2 pl-1 pr-4;
  }

  header img {
    @apply w-auto rounded-lg shadow-lg border border-indigo-300;
    box-shadow: 0px 0px 15px -1px rgba(129, 140, 222, 0.4);
    max-height: 40vh;
    max-width: 35vw;
  }

  .caption {
    @apply w-full flex p-1;
    background: #242425;
  }

  .caption p {
    @apply ml-4 text-sm font-light text-indigo-100 italic;
  }

  .updates-nav {
    @apply pl-4 my-2 flex flex-row justify-start items-start;
  }

  .updates-nav a {
    @apply text-indigo-600 p-2 underline rounded;
    @apply hover:bg-indigo-100 hover:shadow;
  }
</style>
