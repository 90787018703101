<script>
  import { onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';

  import { apiEndpoint, token, fetchOpts } from '../../stores/default';

  import { format, parseISO } from 'date-fns';

  import UserActions from './UserActions.svelte';

  const { addNotification } = getNotificationsContext();

  const alert = (text, type = 'warning') =>
    addNotification({
      text: text,
      position: 'top-right',
      type: type,
      removeAfter: 4000,
    });

  $: allUsers = [];
  let allUsersErr;

  const getAllUsers = async () => {
    try {
      const res = await fetch(`${$apiEndpoint}/private/users`, {
        ...$fetchOpts,
      });
      const json = await res.json();

      if (json) {
        allUsers = json;
      }
    } catch (err) {
      console.log('error loading user list: ', err);
      return (allUsersErr = `Could not lose user list, see console for more`);
    }
  };

  const getName = pro => {
    if (pro.fname && pro.lname) return `${pro.fname} ${pro.lname}`;
    else if (pro.fname) return pro.fname;

    return false;
  };

  onMount(() => {
    getAllUsers();
  });
</script>

<div class="cont">
  <section class="header">
    <h1>Admin Settings</h1>
  </section>
  <section class="users">
    <h2>all registered users</h2>
    {#if allUsers.length > 0}
      <table>
        <tr>
          <th>name</th>
          <th>email</th>
          <th>username</th>
          <th>joined</th>
          <th>role</th>
          <th>actions</th>
        </tr>
        {#each allUsers as user}
          <tr>
            <td>
              {#if getName(user.profile)}
                {getName(user.profile)}
              {:else}
                -
              {/if}
            </td>
            <td>{user.email}</td>
            <td>{user.userSlug}</td>
            <td>{format(parseISO(user.createdAt), 'MMM do, y')}</td>
            <td>{user.isAdmin ? 'Admin' : 'Member'}</td>
            <td><UserActions userToEdit={user} /></td>
          </tr>
        {/each}
      </table>
    {:else if allUsersErr}
      <h3>Error fetching list of all users!</h3>
      <p>{allUsersErr}</p>
    {:else}
      <p>Loading user list...</p>
    {/if}
  </section>
</div>

<style>
  .cont {
    @apply w-full flex flex-col justify-start items-start bg-gray-300 p-2;
  }

  section {
    @apply p-2 my-2 bg-gray-200 rounded-sm;
  }

  section.header {
    @apply px-4 py-2 bg-gray-700 text-white rounded-lg shadow;
  }

  section.users {
    @apply bg-gray-50 rounded shadow;
  }

  section.users h2 {
    @apply font-normal text-xl p-2;
  }

  h1 {
    @apply text-2xl;
  }

  table {
    @apply text-center w-full shadow;
  }

  th {
    @apply font-normal p-2 text-gray-800 bg-gray-300;
  }

  tr {
    @apply w-full;
  }

  tr:first-of-type {
    @apply rounded-t-lg;
  }

  tr:nth-child(even) {
    @apply bg-white;
  }

  tr:nth-child(odd) {
    @apply bg-gray-100;
  }

  td {
    @apply p-2 text-sm;
  }
</style>
