<script>
  import { createEventDispatcher } from 'svelte';
  import {
    user,
    apiEndpoint,
    fetchOpts,
    editingUpdateDelta,
  } from './../../../stores/default';

  import { clickOutside } from './../../../components/clickOutside';
  import { navigate } from 'svelte-routing';

  let visible = false;

  const toggleVisible = () => {
    visible = !visible;
  };

  const dispatch = createEventDispatcher();

  const passUpRefreshUpdates = () => {
    dispatch('passUpRefreshUpdates');
  };

  const handleEditUpdate = update => {
    $editingUpdateDelta = update;
    navigate('/make-update');
  };

  const handleDeleteUpdate = deleteId => {
    fetch(`${$apiEndpoint}/private/updates`, {
      ...$fetchOpts,
      method: 'DELETE',
      body: JSON.stringify({ id: deleteId }),
    })
      .then(res => res.json())
      .then(json => {
        passUpRefreshUpdates();
      })
      .catch(err => {
        console.log('Error deleting update!', err);
      });
  };

  const handleTogglePin = updateId => {
    fetch(`${$apiEndpoint}/private/updates/pin/${updateId}`, {
      ...$fetchOpts,
      method: 'PUT',
    })
      .then(res => res.json())
      .then(json => {
        passUpRefreshUpdates();
      })
      .catch(err => {
        console.log('Error pinning/unpinning update:', err);
      });
  };

  const handleClickOutside = event => {
    if (visible) toggleVisible();
  };

  export let updateData;
</script>

<div class="menu-cont">
  <div>
    <button
      type="button"
      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      id="options-menu"
      aria-haspopup="true"
      aria-expanded="true"
      on:click={toggleVisible}
    >
      Options
      <svg
        class="-mr-1 ml-2 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>

  <div class={visible ? 'menu' : 'menu hidden'}>
    <div
      use:clickOutside
      on:click_outside={handleClickOutside}
      class="py-1"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      {#if $user.isAdmin}
        <button on:click={() => handleTogglePin(updateData._id)}>
          Pin/Unpin Post
        </button>
      {/if}
      <button
        class="option-button"
        role="menuitem"
        on:click={handleEditUpdate(updateData)}>Edit Post</button
      >
      <button
        class="option-button"
        role="menuitem"
        on:click={handleDeleteUpdate(updateData._id)}
      >
        Delete Post
      </button>
    </div>
  </div>
</div>

<style lang="scss">
  .menu-cont {
    @apply relative inline-block text-left;
    margin-left: 40px;
  }

  .option-button {
    @apply block w-full px-3 py-4 text-sm text-gray-700 border-0 bg-transparent;

    &:hover {
      @apply bg-gray-100 text-gray-900;
    }
  }

  .menu {
    @apply origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white;
    z-index: 2;

    &.hidden {
      display: none;
    }
  }
</style>
