<script>
  import { onDestroy, onMount } from 'svelte';
  import Quill from 'quill';
  import { getNotificationsContext } from 'svelte-notifications';
  import { format, parseISO } from 'date-fns';

  import {
    apiEndpoint,
    publicEndpoint,
    fetchOpts,
    editingUpdateDelta,
  } from './../../stores/default';

  let quill, editor;

  let IS_EDITING = false;

  const { addNotification } = getNotificationsContext();

  const pageTexts = {
    title: 'Create a New Update',
  };

  const alert = (text, type = 'warning', removeAfter = 10000) =>
    addNotification({
      text: text,
      position: 'top-right',
      type: type,
      removeAfter: removeAfter,
    });

  const handleSubmit = () => {
    const delta = quill.getContents();

    let reqEndpt = `${$apiEndpoint}/private/updates`;
    let reqMethod = 'POST';
    let reqBody = { updateData: delta };
    // New post, or update?
    if (IS_EDITING) {
      reqEndpt += `/${$editingUpdateDelta._id}`;
      reqMethod = 'PUT';
    }

    let opts = {
      ...$fetchOpts,
      method: reqMethod,
      body: JSON.stringify(reqBody),
    };
    opts.headers['Content-Type'] = 'application/json';

    fetch(reqEndpt, opts)
      .then(res => {
        if (res.status == 200) {
          if (!IS_EDITING) quill.setContents([{ insert: '\n' }]);

          alert(IS_EDITING ? 'Changes saved!' : 'Update posted!', 'success');
        } else {
          alert('Sorry, there was an error, please try again later.');
        }
      })
      .catch(err => {
        console.log('Error posting update', err);
      });
  };

  // done uploading, put the reference in the editor
  const insertImageToEditor = filename => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', `/data/images/${filename}`);
  };

  const postImage = file => {
    alert('Saving image, this could take a second...', 'info');

    const fd = new FormData();
    fd.append('image', file);

    let fo = {
      ...$fetchOpts,
      method: 'POST',
      body: fd,
    };
    delete fo.headers['Content-Type'];

    fo.headers['X-Access-Token'] = localStorage.getItem('jwt_token');

    fetch(`${$apiEndpoint}/private/file/embed`, fo)
      .then(res => {
        if (!res.ok) return console.warn('error saving image to server: ', res);
        return res.text();
      })
      .then(text => {
        insertImageToEditor(text);
        alert('Image saved', 'success');
      })
      .catch(err => {
        console.error('Error posting image: ', err);
        alert('Server error saving file, sorry!', 'error');
      });
  };

  export const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    // input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        postImage(file);
      } else {
        console.warn('You could only upload images.');
      }
    };
  };

  export let toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'],
  ];

  onMount(async () => {
    quill = new Quill(editor, {
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: { image: imageHandler },
        },
      },
      theme: 'snow',
      placeholder: 'write your update here!',
    });

    if ($editingUpdateDelta && Object.keys($editingUpdateDelta).length > 0) {
      IS_EDITING = true;

      console.log('Editing update:');
      console.log($editingUpdateDelta);
      quill.setContents($editingUpdateDelta.delta);

      pageTexts.title = 'Editing an Update';
      pageTexts.createdAt = format(
        parseISO($editingUpdateDelta.createdAt),
        'h:mm a, MMM do, yyyy'
      );
    }
  });

  onDestroy(() => {
    $editingUpdateDelta = {};
  });
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>

<div class="flex flex-col justify-start items-center p-6">
  <section class="w-full flex flex-col justify-start items-center">
    <h2 class="mb-1 text-center">{pageTexts.title}</h2>
    {#if pageTexts.createdAt}
      <p class="text-center font-light mb-4">written {pageTexts.createdAt}</p>
    {/if}
    <div class="editor-wrapper bg-gray-50 w-full">
      <div bind:this={editor} />
    </div>
  </section>
  <section class="save-button">
    <button class="save" on:click={handleSubmit}>Save</button>
  </section>
  <section class="tips">
    <h3 class="p-1 px-2 mb-2 border-b-2 border-indigo-300 text-center">tips</h3>
    <ul>
      <li>Add a photo or two, using the picture icon.</li>
      <li>
        Add some styling to your update with headings, bold, italics, etc.!
      </li>
      <li>
        Add a link by typing what you'd like to call it (ie, "Lars’ Birthday
        Photos"), then highlighting the name and clicking the hyperlink chain
        button in the toolbar. A window will pop open in which to paste the URL.
        The name (ie, "Lars’ Birthday Photos") will then become clickable text.
      </li>
    </ul>
  </section>
</div>

<style lang="scss">
  section.save-button {
    @apply w-full p-0 mt-16 flex justify-center items-center;
  }

  .save {
    @apply mt-2 p-2 px-8 hover:bg-green-100 hover:border-green-700 hover:text-green-700;
  }

  .tips {
    @apply w-3/4 flex flex-col justify-start items-start mt-8 p-4 rounded bg-white shadow-lg;
  }

  .tips ul {
    @apply list-disc ml-8;
  }

  // .tips ul > ul {
  //   @apply list-none ml-4;
  // }

  .editor-wrapper :global(.ql-editor) {
    @apply text-base leading-loose p-4;
  }

  .editor-wrapper :global(.ql-editor h1) {
    @apply mb-2;
  }
  .editor-wrapper :global(.ql-editor h2) {
    @apply mb-2;
  }
  .editor-wrapper :global(.ql-editor h3) {
    @apply mb-2;
  }
  .editor-wrapper :global(.ql-editor h4) {
    @apply mb-2;
  }

  .editor-wrapper :global(.ql-editor p) {
    @apply mb-1;
  }
  .editor-wrapper :global(.ql-editor ul) {
    @apply mb-1;
  }
  .editor-wrapper :global(.ql-editor ol) {
    @apply mb-1;
  }
</style>
