<script>
  import { Route } from 'svelte-routing';

  import Login from './Login.svelte';
  import Signup from './Signup.svelte';
  import Forgot from './ForgotPassword.svelte';
</script>

<div
  class="h-screen w-screen mx-auto flex flex-col justify-center items-center bg-gradient-to-r from-indigo-400 to-sky-400"
>
  <h1 class="text-4xl font-light text-gray-50 my-4 p-1">GillKendig.net</h1>
  <Route path="/">
    <Login />
  </Route>
  <Route path="/signup">
    <Signup />
  </Route>
  <Route path="/forgot-password">
    <Forgot />
  </Route>
</div>
